import Maybe from "data.maybe";
import { complement, includes } from "ramda";

/**
 * Opposite of ramda's include. Returns true if query is not in list
 * @type {Curry.Curry<(needle: A, haystack: Array<A> | string) => boolean>}
 * @returns {boolean}
 * @template A
 */
export const doesntInclude = complement(includes);

/**
 * Safely wraps the first element in an array in a Maybe
 * @param {Array<T>} array
 * @returns {Maybe<T>} Maybe holding the first element in the list or Nothing
 * @template T
 */
export const safeHead = array => Maybe.fromNullable(array[0]);
