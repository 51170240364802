import { compose, multiply } from "ramda";

export const secondsInMs = multiply(1000);
export const minutesInMs = compose(
  secondsInMs,
  multiply(60)
);
export const hoursInMs = compose(
  minutesInMs,
  multiply(60)
);
export const daysInMs = compose(
  hoursInMs,
  multiply(24)
);
export const weeksInMs = compose(
  daysInMs,
  multiply(7)
);
