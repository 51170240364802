import { curry, isNil } from "ramda";

const makeUrlParam = (key, value) => `${key}=${value}`;

const addUrlParam = curry((key, value, params) =>
  params
    .split("&")
    .concat(makeUrlParam(key, value))
    .join("&")
);

export const addParamToUrl = curry((key, value, url) => {
  const [base, params] = url.split("?");
  return [
    base,
    params ? addUrlParam(key, value, params) : makeUrlParam(key, value)
  ].join("?");
});

export const addParamsToUrl = curry((params, url) =>
  Object.keys(params)
    .filter(key => !isNil(params[key]))
    .reduce((acc, key) => addParamToUrl(key, params[key], acc), url)
);
