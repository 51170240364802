import Task from "data.task";
import { curry } from "ramda";
import { toTask } from "../data.task";

export const foldMaybe = curry((fallback, f, maybe) =>
  maybe.map(f).getOrElse(fallback)
);

export const taskFromMaybe = curry((leftValue, f, maybe) =>
  maybe.map(toTask(f)).getOrElse(Task.rejected(leftValue))
);
