import { useLayoutEffect, useState } from "react";

function useRefInRender<A>(ref: React.RefObject<A>) {
  const [element, setElement] = useState(ref.current);

  useLayoutEffect(() => {
    if (ref.current) {
      setElement(ref.current);
    }
  }, []); // eslint-disable-line

  return element;
}

export default useRefInRender;
