/**
 * toInteger :: number -> number
 *
 * Removes decimals from JS numbers
 *
 * @param  {number} x the number to remove
 * @return {number} the number in integer form
 */
export const toInteger = x => parseInt(x.toFixed(), 10);

/**
 * isNegative :: number -> number
 *
 * Determines if a number is negative
 */
export const isNegative = x => x < 0;
