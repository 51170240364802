import { curry } from "ramda";

export const iIndexBy = curry((getKey, acc, item) =>
  acc.set(getKey(item), item)
);

export const immutablePath = curry((path, structure) =>
  path.reduce(
    (acc, key) =>
      acc
        ? typeof acc.get === "function"
          ? acc.get(key)
          : acc[key]
          ? acc[key]
          : undefined
        : acc,
    structure
  )
);

export const immutableGet = curry((prop, structure) => structure.get(prop));
